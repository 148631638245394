import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
  ChangeEvent,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { styled } from "@mui/system";
import { Box, Typography, Link } from "@mui/material";
import { ChatMessage, OrderCompletionMessage, TypingMessage } from "./Messages";
import RecommendedQuestions from "./RecommendedQuestions";
import MessageInput from "./MessageInput";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import { useSubdomain } from "../../contexts/SubdomainContext";
import { useViewportHeight } from "../../hooks/useViewportHeight";
import { handleChatError } from "../../utils/errorHandler";
import { postChatCompletion, postPreviewOrder } from "../../api";
import { Message, Order } from "../../types";

const ChatWindow = () => {
  const { isLoading: isAuthLoading, user } = useAuth0();

  const [conversationId] = useState(uuidv4());
  const [messages, setMessages] = useState<Message[]>([]);
  const [streamedMessage, setStreamedMessage] = useState("");
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [displayMessageStream, setDisplayMessageStream] = useState(true);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [callingFunction, setCallingFunction] = useState(false);
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const [order, setOrder] = useState<Order>();
  const [showOrderCompletion, setShowOrderCompletion] = useState(false);

  const model = MODELS.GPT_35;

  const { subdomain } = useSubdomain();
  const messageContainerRef = useRef<HTMLElement | null>(null);
  const viewportHeight = useViewportHeight();
  const { enqueueSnackbar } = useSnackbar();

  const handleOrderEvent = useCallback((order: Order) => {
    setShowOrderSummary(true);
    setShowConfirmButton(false);
    setCallingFunction(false);
    setOrder(order);
  }, []);

  const processEventStream = useCallback(
    async (
      reader: ReadableStreamDefaultReader,
      decoder: TextDecoder,
      currentMessage: string,
      data: string
    ) => {
      const { done, value } = await reader.read();

      if (done) {
        setDisplayMessageStream(false);
        setMessages((prevMessages) => [
          ...prevMessages,
          createAssistantMessage(currentMessage),
        ]);
        return;
      }

      data += decoder.decode(value);

      if (data.includes("\n\n")) {
        const parts = data.split("\n\n");

        parts.forEach((part, index) => {
          // Don't parse the last part because it might be an incomplete message
          if (index < parts.length - 1) {
            const eventData = JSON.parse(part.replace("data: ", ""));

            if (eventData.message) {
              const content = eventData.message.content;
              if (content) {
                if (content.trim().toLowerCase() === "confirm") {
                  setShowConfirmButton(true);
                }
                currentMessage += content;
                setStreamedMessage((message) => message + content);
              }
            }
            if (eventData.error) {
              throw eventData.error;
            }
          }
        });

        // Keep the last part because it might be an incomplete message
        data = parts[parts.length - 1];
      }

      await processEventStream(reader, decoder, currentMessage, data);
    },
    [setMessages, setDisplayMessageStream, setStreamedMessage]
  );

  const createChatCompletion = useCallback(
    async (messages: Message[]) => {
      try {
        const messageHistory = messages.map(
          (message) => message.choices[0].message
        );

        const chatCompletionRequest = {
          model: model,
          messages: messageHistory,
          conversation_id: conversationId,
          metadata: {
            user: { id: user?.sub, first_name: user?.given_name },
          },
        };

        const response = await postChatCompletion(
          chatCompletionRequest,
          subdomain || ""
        );

        setDisplayMessageStream(true);
        setLoading(false);

        if (response.body) {
          const reader = response.body.getReader();
          const decoder = new TextDecoder("utf-8");
          let data = "";
          let currentMessage = "";
          await processEventStream(reader, decoder, currentMessage, data);
        }
      } catch (error) {
        handleChatError(error, enqueueSnackbar);
      } finally {
        setDisplayMessageStream(false);
        setLoading(false);
      }
    },
    [
      model,
      subdomain,
      conversationId,
      user,
      processEventStream,
      setDisplayMessageStream,
      setLoading,
      enqueueSnackbar,
    ]
  );

  const createOrderPreview = useCallback(
    async (messages: Message[]) => {
      try {
        const messageHistory = messages.map(
          (message) => message.choices[0].message
        );

        const chatCompletionRequest = {
          model: MODELS.GPT_35_16k,
          messages: messageHistory,
          conversation_id: conversationId,
        };

        const response = await postPreviewOrder(
          chatCompletionRequest,
          subdomain || ""
        );

        const jsonResponse = await response.json();
        if ("order" in jsonResponse) {
          setLoading(false);
          handleOrderEvent(jsonResponse.order);
        } else if ("error" in jsonResponse) {
          throw jsonResponse.error;
        }
      } catch (error) {
        handleChatError(error, enqueueSnackbar);
      } finally {
        setLoading(false);
      }
    },
    [subdomain, conversationId, setLoading, handleOrderEvent, enqueueSnackbar]
  );

  useEffect(() => {
    if (!isAuthLoading && messages.length === 0) {
      createChatCompletion([]);
    }
  }, [createChatCompletion, isAuthLoading, messages]);

  useLayoutEffect(() => {
    if (messageContainerRef.current) {
      const lastChild = messageContainerRef.current.lastChild as Element;
      const distanceFromTop = lastChild.getBoundingClientRect().top;
      if (distanceFromTop > 108) {
        lastChild.scrollIntoView();
      }
    }
  }, [
    messages,
    streamedMessage,
    callingFunction,
    showOrderSummary,
    showOrderCompletion,
  ]);

  const handleSendMessage = (message: string) => {
    setShowOrderCompletion(false);
    setShowOrderSummary(false);
    setShowConfirmButton(false);
    setStreamedMessage("");
    setLoading(true);
    const userMessage: Message = {
      created: Math.floor(Date.now() / 1000),
      choices: [
        {
          message: {
            role: "user",
            content: message,
          },
        },
      ],
    };
    const newMessages = [...messages, userMessage];
    setMessages(newMessages);
    createChatCompletion(newMessages);
    setInput("");
  };

  const handleCallFunction = () => {
    setLoading(true);
    setCallingFunction(true);
    createOrderPreview(messages).finally(() => setCallingFunction(false));
  };

  return (
    <Container vh={viewportHeight}>
      <MessageContainer ref={messageContainerRef}>
        {messages.map((message, i) => (
          <ChatMessage
            key={i}
            message={message}
            showButton={
              showConfirmButton &&
              !displayMessageStream &&
              i === messages.length - 1
            }
            disableButton={callingFunction}
            handleCallFunction={handleCallFunction}
            formatContent={i === 0}
          />
        ))}
        {loading && <TypingMessage />}
        {!loading && displayMessageStream && (
          <ChatMessage
            key="message_stream"
            message={createAssistantMessage(streamedMessage)}
            formatContent={messages.length === 0}
          />
        )}
        {showOrderSummary && order && (
          <OrderCompletionMessage
            order={order}
            setOrder={setOrder}
            showOrderCompletion={showOrderCompletion}
            setShowOrderCompletion={setShowOrderCompletion}
            conversationId={conversationId}
          />
        )}
      </MessageContainer>
      {!showConfirmButton && !showOrderSummary && (
        <RecommendedQuestions
          messages={messages}
          handleSendMessage={handleSendMessage}
          loading={loading}
          displayMessageStream={displayMessageStream}
        />
      )}
      <MessageInput
        input={input}
        handleChange={(e: ChangeEvent<HTMLInputElement>) =>
          setInput(e.target.value)
        }
        handleSendMessage={handleSendMessage}
        loading={loading}
        displayMessageStream={displayMessageStream}
      />
      <Footer variant="caption" color="textSecondary">
        Powered by{" "}
        <Link
          color="textSecondary"
          href="https://baristagpt.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          BaristaGPT
        </Link>
      </Footer>
    </Container>
  );
};

export default ChatWindow;

const MODELS = Object.freeze({
  GPT_4: "gpt-4",
  GPT_35: "gpt-3.5-turbo",
  GPT_35_16k: "gpt-3.5-turbo-16k",
});

const createAssistantMessage = (content: string): Message => {
  return {
    created: Math.floor(Date.now() / 1000),
    choices: [
      {
        message: {
          role: "assistant",
          content: content,
        },
      },
    ],
  };
};

interface ContainerProps {
  vh: number;
}

const Container = styled(Box)(({ vh }: ContainerProps) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "0 2%",
  height: `calc(${vh}px - 100px)`,
}));

const MessageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  flex: 1,
  padding: `${theme.spacing(1.25)} 0`,
}));

const Footer = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginTop: theme.spacing(1.25),
}));
